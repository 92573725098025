<template>
  <div>
    <fieldsRows
        :templateContent="connectorsContent"
        @field-value-changed="updateFieldValue(...arguments)"
        :valueLine="config"
        :isValid="isValid"></fieldsRows>
    <input type="hidden" :value="JSON.stringify(localValue)" name="c_obj"/>
  </div>
</template>


<script>
import fieldsRows from '@/commonComponents/fieldsRows.vue';

export default {
  props: ['result', 'field', 'fieldAttributes', 'value', 'isValid'],
  components: {fieldsRows},
  data() {
    return {
      check: {},
      connectorsContent: {
        fields: {},
        usedRef: '',
        contentID: this.result.additionlFormAtts['data-className'],
      },
      configObject: {
        jira: {
          url: {
            class: 'checkURI required',
            value: '',
          },
          user: {
            type: 'text',
            fieldAttrInput: {class: 'required'},
          },
          password: {
            type: 'password',
            fieldAttrInput: {class: 'required'},
          },
          project: {
            type: 'text',
            fieldAttrInput: {class: 'required'},
          },
          issuetype: {
            type: 'text',
            fieldAttrInput: {class: 'required'},
          },
        },
        'jira-update': {
          action: {
            type: 'select',
            class: 'connectorActionSelector required',
            options: [
              ['comment', 'Comment'],
              ['transition', 'Transition'],
              ['edit', 'Edit'],
            ],
            fieldAttrInput: {class: 'required'},
          },
          transitionId: {type: 'text'},
          edit: {
            type: 'text',
            inputFieldFormatter: 'indexValueAdder',
          },
          url: {
            type: 'text',
            fieldAttrInput: {class: 'required'},
          },
          user: {
            type: 'text',
            fieldAttrInput: {class: 'required'},
          },
          password: {
            type: 'password',
            fieldAttrInput: {class: 'required'},
          },
          project: {
            type: 'text',
            fieldAttrInput: {class: 'required'},
          },
        },
        slack: {
          webhook: {
            type: 'text',
            fieldAttrInput: {class: 'checkURI required'},
          },
        },
        email: {
          address: {
            type: 'text',
            fieldAttrInput: {class: 'required email'},
          },
        },
        microfocus_alm: {
          url: {
            type: 'text',
            fieldAttrInput: {class: 'required url'},
          },
          client: {
            type: 'text',
            fieldAttrInput: {class: 'required'},
          },
          secret: {
            type: 'text',
            fieldAttrInput: {class: 'required'},
          },
          project: {
            type: 'text',
            fieldAttrInput: {class: 'required'},
          },
          domain: {
            type: 'text',
            fieldAttrInput: {class: 'required'},
          },
          folder: {
            type: 'AlmFolders',
            fieldAttrInput: {class: 'required'},
            options: [],
          },
        },
      },
    };
  },
  created() {
    this.buildConfig(
        this.result.valueLine.connectorTypeSelector,
        this.config.action,
    );
  },
  watch: {
    'result.valueLine.connectorTypeSelector': function (newVal) {
      this.buildConfig(newVal, this.config.action);
      this.$set(this.localValue, 'type', newVal);
      this.$emit('input', JSON.stringify(this.localValue));
    },
    'config.action': function (newVal) {
      this.buildConfig(this.connectorTypeSelector, newVal);
    },
  },
  methods: {
    updateUsedRef(usedRef) {
      this.usedRef = usedRef;
    },
    buildConfig(type, action) {
      if (typeof type != 'undefined') {
        this.$set(
            this.connectorsContent,
            'fields',
            JSON.parse(JSON.stringify(this.configObject[type])),
        );
        if (this.connectorTypeSelector === 'jira-update') {
          if (action === 'comment') {
            this.$delete(this.connectorsContent.fields, 'transitionId');
            this.$delete(this.connectorsContent.fields, 'edit');
          } else if (action === 'transition') {
            this.$delete(this.connectorsContent.fields, 'edit');
          } else if (action === 'edit') {
            this.$delete(this.connectorsContent.fields, 'transitionId');
          } else {
            this.$delete(this.connectorsContent.fields, 'transitionId');
            this.$delete(this.connectorsContent.fields, 'edit');
          }
        }
        this.$set(this.connectorsContent, 'enableAdd', false);

        this.$set(
            this.connectorsContent,
            'fields',
            this.languageHelpProvider(this.connectorsContent.fields, 'connectors'),
        );
        // update the content of the child from the store
        if (
            typeof this.$store.state.dialogDataObject[this.usedRef] != 'undefined'
        ) {
          this.$set(
              this.$store.state.dialogDataObject[this.usedRef].result,
              'fields',
              this.connectorsContent.fields,
          );

          this.$store.commit(
              'dialogResultChanged',
              this.$store.state.dialogDataObject[this.usedRef],
          );
        }
      }
    },
    updateFieldValue(index, value) {
      if (index !== 'folder') {
        this.$set(this.config, index, value);
      } else {
        this.$set(this.config, 'folderId', value.id);
        this.$set(this.config, 'folderName', value.name);
      }
      this.checkConfig(this.config);
    },
    checkConfig(newVal) {
      if (this.connectorTypeSelector === 'jira-update') {
        if (newVal.action === 'comment') {
          this.$delete(newVal, 'transitionId');
          this.$delete(newVal, 'edit');
        } else if (newVal.action === 'transition') {
          this.$delete(newVal, 'edit');
        } else if (newVal.action === 'edit') {
          this.$delete(newVal, 'transitionId');
        } else {
          this.$delete(newVal, 'transitionId');
          this.$delete(newVal, 'edit');
        }
      }
      this.$set(this.localValue, 'config', newVal);
      this.$emit('input', JSON.stringify(this.localValue));
    },
  },
  computed: {
    connectorTypeSelector() {
      return this.result.valueLine.connectorTypeSelector;
    },
    localValue() {

      let value;

      try {
        value = JSON.parse(this.value);
      } catch (error) {
        value = {};
      }
      return value;
    },
    config: {
      get() {
        let config = {};
        if (typeof this.localValue.config != 'undefined') {
          config = this.localValue.config;
        }
        return config;
      },
      set(newVal) {
        this.checkConfig(newVal);
      },
    },
  },
};
</script>
